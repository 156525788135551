define('core/components/dt-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    didInsertElement: function didInsertElement() {
      console.log('reinit');
      this.send('uuid');
      this.send('init');
    },

    actions: {
      uuid: function uuidv4() {
        if (!this.get('uuid')) {
          this.set('uuid', ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
            return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
          }));
        }
      },
      init: function init() {
        var _this = this;
        var title = this.get('title');
        if (title && this.get('subTitle')) {
          title = title + ' -  ' + this.get('subTitle');
        }
        if (title) {
          title = title.toLowerCase();
        }
        console.log(title);
        if (this.$('table:visible') && this.$('table:visible').length > 0 && !this.$('table').hasClass('dataTable') && this.$('tbody tr').length > 0) {

          var column = this.get('sortColumn') || 0;
          var direction = this.get('sortDirection') || 'desc';
          var pageLength = this.get('pageLength') || 100;

          this.$('table:visible').dataTable({
            lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
            dom: 'Blfrtip',
            columnDefs: _this.get('columns'),
            buttons: [{ filename: title || this.get('uuid'), extend: 'copyHtml5' }, { filename: title || this.get('uuid'), extend: 'excelHtml5', exportOptions: { columns: ':visible' } }, { filename: title || this.get('uuid'), extend: 'csvHtml5' }, { filename: title || this.get('uuid'), extend: 'pdfHtml5', exportOptions: { columns: ':visible' } }],
            pageLength: pageLength,
            order: [[column, direction]] });
        } else {
          setTimeout(function () {
            _this.send('init');
          }, 1000);
        }
      },
      toggleField: function toggleField(field) {
        var table = this.$('table.' + this.get('uuid')).dataTable();
        var column = table.api().column(field.column);
        column.visible(!column.visible());
      },
      callAction: function callAction(column, item) {
        this.sendAction(column.action, item);
      }
    }
  });
});